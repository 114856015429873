// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, deleteUser } from 'firebase/auth';
import { getFirestore, collection, getDocs, deleteDoc, doc } from 'firebase/firestore'; 
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBlgjl-WLQC2XxlGJc6yfV6HoxChHhVH04",
  authDomain: "minemii.firebaseapp.com",
  databaseURL: "https://minemii-default-rtdb.firebaseio.com",
  projectId: "minemii",
  storageBucket: "minemii.appspot.com",
  messagingSenderId: "598261072245",
  appId: "1:598261072245:web:8000003dfdf525aee1533c",
  measurementId: "G-HYWS0N80TK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize storage
const API_KEY = "AIzaSyDkeLw_pXFj5za2qaImIt8MnCss09L6Py0"; // Replace with your YouTube Data API key

async function deleteUserData(user) {
  try {
    const userEmail = user.email;
    
    // Get user videos collection
    const userVideoCollection = collection(db, 'videos', userEmail, 'userVideos');
    const videoSnapshot = await getDocs(userVideoCollection);

    // Delete all videos
    for (const videoDoc of videoSnapshot.docs) {
      await deleteDoc(videoDoc.ref);
    }

    // Delete user document
    const userDoc = doc(db, 'users', user.uid);
    await deleteDoc(userDoc);

    // Delete user account
    await deleteUser(user);
    
    alert('Your account and all associated videos have been deleted.');
  } catch (error) {
    console.error('Error deleting user data:', error);
    alert('Failed to delete account. Please try again.');
  }
}

export { auth, db, storage, API_KEY, deleteUserData };
