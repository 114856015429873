// Main.js
import React, { useState, useEffect } from 'react';
import { loadAllVideos, addVideo } from '../script';
import Header from './Header';
import { auth } from '../firebase';
import '../styles/Main.css';
import youtubeLogo from '../components/logo/y.png'; 
import netflixLogo from '../components/logo/n.png';
import todLogo from '../components/logo/t.png';
import disneyLogo from '../components/logo/d.png';
import shahidLogo from '../components/logo/s.png';
import Footer from './Footer';
import '../scrollbar.css';

function Main() {
  const [videoUrl, setVideoUrl] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  useEffect(() => {
    if (selectedPlatform === 'youtube') {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          loadAllVideos(); // Load videos when YouTube is selected and user is authenticated
        }
      });
      return () => unsubscribe();
    }
  }, [selectedPlatform]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setVideoUrl(value);
    setIsButtonDisabled(value.trim() === '');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
      addVideo(videoUrl); // Pass the videoUrl if necessary
      setVideoUrl(''); // Clear input after adding
      setIsButtonDisabled(true);
    }
  };

  const handleLogoClick = (platform) => {
    setSelectedPlatform(platform); // Set the selected platform

    if (platform === 'youtube') {
      // Load videos for YouTube
      if (auth.currentUser) {
        loadAllVideos();
      }
    }
    // No need to handle other platforms here
  };

  return (
    <main className="main-container">
      <div className="header-main"></div>
      <Header />
      <div className="logo-container">
        <ul>
          <li className="youtube">
            <button className="logo-button" onClick={() => handleLogoClick('youtube')}>
              <img src={youtubeLogo} alt="YouTube Logo" className="icon icon3" id="youtube" />
            </button>
          </li>
          <li className="netflix">
            <button className="logo-button" onClick={() => handleLogoClick('netflix')}>
              <img src={netflixLogo} alt="Netflix Logo" className="icon icon3" id="netflix" />
            </button>
          </li>
          <li className="tod">
            <button className="logo-button" onClick={() => handleLogoClick('tod')}>
              <img src={todLogo} alt="TOD Logo" className="icon icon3" />
            </button>
          </li>
          <li className="disney">
            <button className="logo-button" onClick={() => handleLogoClick('disney')}>
              <img src={disneyLogo} alt="Disney Logo" className="icon icon3" />
            </button>
          </li>
          <li className="shahid">
            <button className="logo-button" onClick={() => handleLogoClick('shahid')}>
              <img src={shahidLogo} alt="Shahid Logo" className="icon icon3" />
            </button>
          </li>
        </ul>
      </div>

      {!selectedPlatform && (
        <div className="default-message">
          <h2>Mine Your Own Video!</h2>
          <p> Seamlessly collect and enjoy your favorite videos from across platforms with family and friends.</p>
          <p> Seamlessly collect and enjoy your favorite videos from across platforms with family and friends. Curate your ultimate watchlist with ease!</p>
        </div>
      )}

      {selectedPlatform === 'youtube' && (
        <>
          <section id="videoInput">
            <input 
              type="text" 
              id="videoUrl" 
              placeholder="Enter YouTube URL" 
              value={videoUrl} 
              onChange={handleInputChange} 
              onKeyPress={handleKeyPress} 
            />
            <button onClick={() => { addVideo(videoUrl); setVideoUrl(''); setIsButtonDisabled(true); }} disabled={isButtonDisabled}>
              Add Video
            </button>
          </section>

          <div className="categories-container">
            <div id="allVideos" className="video-category-container"></div>
            <div id="musicVideos" className="video-category-container"></div>
            <div id="sportsVideos" className="video-category-container"></div>
            <div id="kidsVideos" className="video-category-container"></div>
            <div id="gamingVideos" className="video-category-container"></div>
            <div id="otherVideos" className="video-category-container"></div>
          </div>
        </>
      )}

      {selectedPlatform && selectedPlatform !== 'youtube' && (
        <div className="coming-soon-message">
          <h2>Coming Soon!</h2>
          <p>We can't access videos from this platform at the moment.</p>
          <p>Add your favorite YouTube videos using the URL!</p>
        </div>
      )}

      <Footer />
    </main>
  );
}

export default Main;
