import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import '../styles/changeemail.css';
import Footer from './Footer';
function ChangeEmail() {
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { changeEmail, currentUser } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    setError('');
    setMessage('');

    try {
      // Change email in Firebase Authentication
      await changeEmail(newEmail, password);
      
      // Update Firestore 'users' collection with the new email
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        email: newEmail
      });

      setMessage('A verification email has been sent to your new email address. Please verify it to complete the email update.');

      // Navigate to the login page after a short delay
      setTimeout(() => {
        navigate('/login');
      }, 100); // Delay to allow user to see the message
    } catch (error) {
      console.error('Error updating email:', error);
      setError('Failed to update email. Please ensure your password is correct.');
    }
  }

  return (
    <div>
      <Header/>
      <div className="changeemail-container">
    <Header />
    <div>
        <h2>Change Email</h2>
        <p className="c-email">Your Email: {currentUser && currentUser.email}</p>
        {message && <p className="message">{message}</p>}
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
            <div className="form-group-email">
                <label>
                    New Email:
                    <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        required
                    />
                </label>
            </div>
            <div className="form-group-email">
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
            </div>
            <button className="button-email" type="submit">Update Email</button>
        </form>
       
    </div>
</div>
<Footer/>
    </div>
  );
}

export default ChangeEmail;
