import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import Header from './Header';
import '../styles/changepassword.css';
import Footer from './Footer';
export default function ChangePassword() {
  const { currentUser} = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      return setError('New passwords do not match.');
    }

    try {
      setLoading(true);
      // Re-authenticate the user with old password
      const credential = EmailAuthProvider.credential(currentUser.email, oldPassword);
      await reauthenticateWithCredential(currentUser, credential);
      // Update password
      await updatePassword(currentUser, newPassword);
      setSuccess('Password updated successfully!');
      setError('');
    } catch (error) {
      console.error("Error updating password:", error);
      setError('Failed to update password. Please try again later.');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Header />
    <div className="change-password-container">
      <h2>Change Password</h2>
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group-password">
          <label>Old Password</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group-password">
          <label>New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group-password">
          <label>Confirm New Password</label>
          <input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
          />
        </div>
        <button className="button-password" type="submit" disabled={loading}>Update Password</button>
      </form>
    </div>
    <Footer/>
    </div>
  );
}
