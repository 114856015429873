import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import '../styles/Footer.css'; // Import the footer styling

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2024 <b>|</b> minemii Website. All rights reserved.&nbsp;<b>|</b>   </p>
      <Link to="/policy" className="footer-link">&nbsp; <u>Privacy</u> <u>Policy</u> </Link>
    </footer>
  );
}

export default Footer;
