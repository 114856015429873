// src/script.js
import { db, auth, API_KEY } from './firebase';
import { collection, addDoc, query, where, getDocs, deleteDoc, onSnapshot } from 'firebase/firestore';
import './scrollbar.css';

export async function addVideo() {
    const videoUrl = document.getElementById('videoUrl').value.trim();

    if (videoUrl) {
        const videoId = extractVideoId(videoUrl);
        if (videoId) {
            const user = auth.currentUser;
            if (user) {
                await fetchVideoDetails(videoId, user.email);
                document.getElementById('videoUrl').value = '';
            } else {
                alert('You need to be logged in to add videos.');
            }
        } else {
            alert('Invalid YouTube URL. Please enter a valid YouTube video URL.');
        }
    } else {
        alert('Please enter a YouTube URL.');
    }
}

function extractVideoId(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
}

async function fetchVideoDetails(videoId, userEmail) {
    try {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet,status`);
        const data = await response.json();
        if (data.items && data.items.length > 0) {
            const title = data.items[0].snippet.title;
            const thumbnailUrl = data.items[0].snippet.thumbnails.high.url;
            const category = data.items[0].snippet.categoryId;
            const madeForKids = data.items[0].status.madeForKids;

            // Add category mappings
            const categoryMap = {
                '10': 'Music',
                '17': 'Sports',
                '20': 'Gaming',
                // Add more category mappings as needed
            };

            let videoType = categoryMap[category] || 'Other';
            if (madeForKids) {
                videoType = 'Kids';
            }

            await saveVideo(videoId, title, thumbnailUrl, videoType, userEmail);
        } else {
            alert('Failed to fetch video details. Please try again.');
        }
    } catch (error) {
        console.error('Error fetching video details:', error);
        alert('Error fetching video details. Please try again.');
    }
}

async function saveVideo(videoId, title, thumbnailUrl, videoType, userEmail) {
    const videoData = { videoId, title, thumbnailUrl, videoType };
    const userVideoCollection = collection(db, 'videos', userEmail, 'userVideos');
    await addDoc(userVideoCollection, videoData);
}

export function loadAllVideos() {
    const user = auth.currentUser;
    if (user) {
        const userEmail = user.email;
        const userVideoCollection = collection(db, 'videos', userEmail, 'userVideos');

        onSnapshot(userVideoCollection, (snapshot) => {
            const categorizedVideos = {
                Sports: [],
                Music: [],
                Kids: [],
                Gaming: [],
                Other: [],
            };

            snapshot.forEach((doc) => {
                const video = doc.data();
                if (categorizedVideos[video.videoType]) {
                    categorizedVideos[video.videoType].push(video);
                } else {
                    categorizedVideos.Other.push(video);
                }
            });

            displayCategorizedVideos(categorizedVideos);
        });
    }
}

function displayCategorizedVideos(categorizedVideos) {
    const allVideosDiv = document.getElementById('allVideos');
    const sportsDiv = document.getElementById('sportsVideos');
    const musicDiv = document.getElementById('musicVideos');
    const gamingDiv = document.getElementById('gamingVideos');
    const kidsDiv = document.getElementById('kidsVideos');
    const otherDiv = document.getElementById('otherVideos'); // Added for "Other" category

    // Check if DOM elements exist before setting innerHTML
    if (allVideosDiv) allVideosDiv.innerHTML = '';
    if (sportsDiv) sportsDiv.innerHTML = '';
    if (musicDiv) musicDiv.innerHTML = '';
    if (gamingDiv) gamingDiv.innerHTML = '';
    if (kidsDiv) kidsDiv.innerHTML = '';
    if (otherDiv) otherDiv.innerHTML = ''; // Initialize "Other" category

    const videoSections = [
        { div: sportsDiv, videos: categorizedVideos.Sports, title: 'Sports Videos' },
        { div: musicDiv, videos: categorizedVideos.Music, title: 'Music Videos' },
        { div: gamingDiv, videos: categorizedVideos.Gaming, title: 'Gaming Videos' },
        { div: kidsDiv, videos: categorizedVideos.Kids, title: 'Kids Videos' },
        { div: otherDiv, videos: categorizedVideos.Other, title: 'Other Videos' }, // Handle "Other"
        { div: allVideosDiv, videos: Object.values(categorizedVideos).flat(), title: 'All Videos' }
    ];

    // Check if all categories are empty
    const allVideosEmpty = videoSections.every(section => section.videos.length === 0);

    if (allVideosEmpty) {
        if (allVideosDiv) {
            const noVideosMessage = document.createElement('div');
            noVideosMessage.classList.add('no-videos-message');
            noVideosMessage.innerHTML = `
                <h2>Welcome to mine!</h2>
                <p>There are no videos available at the moment.</p>
                <p>Start by adding your favorite YouTube videos using the URL above!</p>
            `;
            allVideosDiv.appendChild(noVideosMessage);
        }
        return;
    }

    videoSections.forEach(section => {
        if (section.videos.length > 0 && section.div) {
            const sectionContainer = document.createElement('div');
            sectionContainer.classList.add('video-category-container');

            const sectionTitle = document.createElement('h2');
            sectionTitle.classList.add('video-category-title');
            sectionTitle.textContent = section.title;

            const sectionVideos = document.createElement('div');
            sectionVideos.classList.add('video-category');

            section.videos.forEach(video => {
                const videoElement = createVideoElement(video);
                sectionVideos.appendChild(videoElement);
            });

            const rightArrow = createScrollArrow('right');
            const leftArrow = createScrollArrow('left');
            sectionVideos.appendChild(rightArrow);
            sectionVideos.appendChild(leftArrow);

            sectionContainer.appendChild(sectionTitle);
            sectionContainer.appendChild(sectionVideos);
            section.div.appendChild(sectionContainer);

            applyScrollFunctionality(sectionVideos, rightArrow, leftArrow);
        }
    });

    // Apply event listeners to all sections after all videos are added
    applyEventListenersToAllSections([sportsDiv, musicDiv, gamingDiv, kidsDiv, otherDiv, allVideosDiv]); // Included otherDiv
}

function createScrollArrow(direction) {
    const arrowButton = document.createElement('button');
    arrowButton.classList.add('scroll-arrow', direction);

    const arrowSvg = direction === 'right' 
        ? `
        <svg class="w-8 h-8 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M10.271 5.575C8.967 4.501 7 5.43 7 7.12v9.762c0 1.69 1.967 2.618 3.271 1.544l5.927-4.881a2 2 0 0 0 0-3.088l-5.927-4.88Z" clip-rule="evenodd"/>
        </svg>`
        : `
        <svg class="w-8 h-8 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M13.729 5.575c1.304-1.074 3.27-.146 3.27 1.544v9.762c0 1.69-1.966 2.618-3.27 1.544l-5.927-4.881a2 2 0 0 1 0-3.088l5.927-4.88Z" clip-rule="evenodd"/>
        </svg>`;

    arrowButton.innerHTML = arrowSvg;
    return arrowButton;
}

function applyScrollFunctionality(container, rightArrow, leftArrow) {
    rightArrow.addEventListener('click', () => {
        container.scrollLeft += container.clientWidth;
    });

    leftArrow.addEventListener('click', () => {
        container.scrollLeft -= container.clientWidth;
    });

    container.addEventListener('scroll', () => {
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
            rightArrow.style.display = 'none';
        } else {
            rightArrow.style.display = 'block';
        }

        if (container.scrollLeft === 0) {
            leftArrow.style.display = 'none';
        } else {
            leftArrow.style.display = 'block';
        }
    });

    // Initial check
    if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        rightArrow.style.display = 'none';
    } else {
        rightArrow.style.display = 'block';
    }

    if (container.scrollLeft === 0) {
        leftArrow.style.display = 'none';
    } else {
        leftArrow.style.display = 'block';
    }
}

function applyEventListenersToAllSections(sections) {
    sections.forEach(section => {
        if (section) {
            const videoItems = section.querySelectorAll('.video');

            videoItems.forEach(videoItem => {
                const videoThumbnail = videoItem.querySelector('.video-thumbnail');
                const deleteButton = videoItem.querySelector('.delete-button');
                const videoId = videoItem.getAttribute('data-video-id');

                if (videoThumbnail) {
                    videoThumbnail.addEventListener('click', (event) => {
                        if (event.target !== deleteButton && !deleteButton.contains(event.target)) {
                            window.location.href = `/video/${videoId}`;
                        }
                    });
                }

                if (deleteButton) {
                    deleteButton.addEventListener('click', async (event) => {
                        event.stopPropagation();
                        const user = auth.currentUser;
                        if (user) {
                            const userEmail = user.email;
                            const userVideoCollection = collection(db, 'videos', userEmail, 'userVideos');
                            const videoQuery = query(userVideoCollection, where('videoId', '==', videoId));
                            const videoSnapshot = await getDocs(videoQuery);

                            videoSnapshot.forEach(async (doc) => {
                                await deleteDoc(doc.ref);
                            });
                            videoItem.remove(); 
                        } else {
                            alert('You need to be logged in to delete videos.');
                        }
                    });
                }
            });
        }
    });
}

function createVideoElement(video) {
    const videoElement = document.createElement('div');
    videoElement.classList.add('video');
    videoElement.setAttribute('data-video-id', video.videoId);

    const videoTitle = document.createElement('h3');
    videoTitle.classList.add('video-title');
    
    // Check if title length exceeds 50 characters
    if (video.title.length > 50) {
        videoTitle.textContent = video.title.substring(0, 50) + '.....';
    } else {
        videoTitle.textContent = video.title;
    }

    const videoThumbnail = document.createElement('img');
    videoThumbnail.src = video.thumbnailUrl;
    videoThumbnail.alt = video.title;
    videoThumbnail.classList.add('video-thumbnail');

    const deleteButton = document.createElement('button');
    deleteButton.classList.add('delete-button');
    deleteButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 69 14" class="svgIcon bin-top">
            <g clip-path="url(#clip0_35_24)">
                <path fill="black" d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_24">
                    <rect fill="white" height="14" width="69"></rect>
                </clipPath>
            </defs>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 69 57" class="svgIcon bin-bottom">
            <g clip-path="url(#clip0_35_22)">
                <path fill="black" d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_22">
                    <rect fill="white" height="57" width="69"></rect>
                </clipPath>
            </defs>
        </svg>
    `;

    // Append thumbnail, delete button, and title to video element
    videoElement.appendChild(videoThumbnail);
    videoElement.appendChild(deleteButton);
    videoElement.appendChild(videoTitle);

    return videoElement;
}
