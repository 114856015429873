import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure firebase is properly set up
import defaultProfilePic from '../components/logo/profile-pic.jpg'; // Default profile picture
import '../styles/Header.css';
import logo from '../components/logo/mine.png';

function Header() {
  const { currentUser, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(defaultProfilePic); // Initialize with default picture
  const dropdownRef = useRef(null);

  async function handleLogout() {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out', error);
    }
  }

  useEffect(() => {
    // Fetch user's profile picture
    async function fetchProfilePicture() {
      if (currentUser) {
        const userDoc = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userDoc);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setProfilePicture(userData.profilePicture || defaultProfilePic);
        }
      }
    }
    fetchProfilePicture();
  }, [currentUser]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="profile-pic-container-h">
        <Link to="/profile">
          <img
            src={profilePicture}
            alt="Profile"
            style={{ width: '35px', height: '35px', borderRadius: '50%', cursor: 'pointer' }}
          />
        </Link>
      </div>
      <div className="header">
        <Link to="/" className="container-header">
          <img src={logo} alt="Website Logo" className="logo-image-h" />
        </Link>
        <div className="list-icon" onClick={() => setIsOpen(!isOpen)}>
          ☰
        </div>
        {isOpen && (
          <div className="dropdown" ref={dropdownRef}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/profile">Profile</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recommendations">Recommendations</Link></li>
              {currentUser && (
                <li>
                  <button onClick={handleLogout}>Sign Out</button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
