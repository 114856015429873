import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import '../styles/VideoPlayer.css'; 
import ArrowLeftLight from './Arrow_left_light.png'; 
import RedImage from './RedWatchPage.png'; 
import BlackImage from './BlackWatchPage.png'; 
import logo from '../components/logo/mine.png'; // Ensure the logo path is correct
import Footer from './Footer';
function VideoPage() {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { currentUser, logout } = useAuth(); 
  const navigate = useNavigate(); 

  const fetchVideo = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const userEmail = user.email;
      const q = query(collection(db, 'videos', userEmail, 'userVideos'), where('videoId', '==', videoId));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setVideo(doc.data());
      });
    }
  }, [videoId]);

  useEffect(() => {
    fetchVideo();
  }, [fetchVideo]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  }

  if (!video) return <div></div>;

  return (
    <div className="video-page-container">
      <button onClick={() => navigate(-1)} className="back-button">
        <img src={ArrowLeftLight} alt="Back" />
      </button>
      <div className="video-header-container">
        <Link to="/" className="logo-link">
          <img src={logo} alt="Website Logo" className="logo-image-h" />
        </Link>
        <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
          ☰
        </div>
        {isOpen && (
          <div className="dropdown-menu" ref={dropdownRef}>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recommendations">Recommendations</Link></li>
              {currentUser && (
                <li>
                  <button onClick={handleLogout}>Sign Out</button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <iframe
        src={`https://www.youtube.com/embed/${video.videoId}`}
        className="video-frame"
        width="1000"
        height="562"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={video.title}
      ></iframe>
      <img src={RedImage} alt="Red Watch Page" className="red-watch-image" />
      <img src={BlackImage} alt="Black Watch Page" className="black-watch-image" />
      <Footer/>
    </div>
  );
}

export default VideoPage;
