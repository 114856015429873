import React from 'react';
import Header from './Header';
import '../styles/App.css';
import RedImageApp from './Red1App.png';
import RedImageAppL from './RedLeft.png';
import Footer from './Footer';
function Recommendations() {
  return (
    <div>
      <Header />
      <main>
        <section className="content-section" style={{ margin: '0px' }}>
          <h2>Recommendations</h2>
          <p>To further help create a safer environment for the <br/> family we highly recommend the use of DF Tube. This <br/> web extension helps:</p>
          <ul>
            <li>Hide YouTube recommendations</li>
            <li>Hide comments</li>
            <li>Hide related videos</li>
            <li>Hide homepage feed</li>
            <li>Customize YouTube interface</li>
          </ul>
          <button 
            className="install-button"
            onClick={() => window.open("https://chromewebstore.google.com/detail/df-tube-distraction-free/mjdepdfccjgcndkmemponafgioodelna?pli=1", "_blank")}
          >
            <svg className="svgIcon" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
            </svg>
            <span className="icon2"></span>
            <span className="tooltip">Download</span>
          </button>
        </section>
      </main>
      <img src={RedImageApp} alt="Red" className="Red-bottom" />
      <img src={RedImageAppL} alt="Red" className="Red-Left" />
      <Footer/>
    </div>
  );
}

export default Recommendations;
