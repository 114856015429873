import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Main from './components/Main';
import About from './components/About';
import Contact from './components/Contact';
import Recommendations from './components/Recommendations';
import Login from './components/Login';
import SignUp from './components/SignUp';
import PrivateRoute from './components/PrivateRoute';
import ForgotPassword from './components/ForgotPassword';
import VideoPage from './components/VideoPage';
import Policy from './components/policy'; // Import PrivacyPolicy component
import { AuthProvider } from './contexts/AuthContext';
import Profile from './components/Profile';
import Changepassword from './components/changepassword';
import ChangeEmail from './components/changeemail';
function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Main />} />
          </Route>
          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/about" element={<PrivateRoute />}>
            <Route path="/about" element={<About />} />
          </Route>
          <Route path="/contact" element={<PrivateRoute />}>
            <Route path="/contact" element={<Contact />} />
          </Route>
          <Route path="/recommendations" element={<PrivateRoute />}>
            <Route path="/recommendations" element={<Recommendations />} />
          </Route>
          <Route path="/changepassword" element={<PrivateRoute />}>
            <Route path="/changepassword" element={<Changepassword />} />
          </Route>
          <Route path="/changeemail" element={<PrivateRoute />}>
            <Route path="/changeemail" element={<ChangeEmail/>} />
          </Route>
          <Route path="/video/:videoId" element={<PrivateRoute />}>
            <Route path="/video/:videoId" element={<VideoPage />} />
          </Route>
          <Route path="/policy" element={<Policy />} /> {/* Add Privacy Policy route */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
